<ng-template [ngIf]="isLoading">
  <div class="d-flex mt-3 justify-content-center">
    <app-loading></app-loading>
  </div>
</ng-template>
<div class="card" *ngIf="!showReport && !isLoading">
  <div class="card-header border-0">
    <div class="row">
      <div class="col-12">
        <h3 class="mb-0">Customer records are only displayed when the time period selected is 6 months or less.</h3>
      </div>
    </div>
  </div>
</div>

<div class="card" *ngIf="showReport && !isLoading">
  <div class="card-header border-0">
    <div class="row">
      <div class="col-6" *ngIf="reportType === 'T'">
        <h3 class="mb-0">Recent&nbsp;Triage&nbsp;KPI&nbsp;Events</h3>
      </div>
      <div class="col-6" *ngIf="reportType === 'C'">
        <h3 class="mb-0">Customers&nbsp;Messaged</h3>
      </div>
      <div class="col-6" *ngIf="reportType === 'E'">
        <h3 class="mb-0">Engagements</h3>
      </div>
      <div *ngIf="reportDetails?.length > 0" class="col-6 text-right">
        <input #search class="search" type="text" placeholder="Search..." (keyup)="onSearch(search.value)" />
      </div>
    </div>
    <div *ngIf="false" class="row">
      <div class="col-2"><i class="fas fa-ban"></i>&nbsp;Series&nbsp;Stopped&nbsp;&nbsp;&nbsp;</div>
      <div class="col-2"><i class="fas fa-user-slash"></i>&nbsp;Do&nbsp;Not&nbsp;Contact</div>
      <div class="col-2" *ngIf="reportType === 'C'">
        <i class="far fa-user-circle"></i>&nbsp;Human-In-The-Loop&nbsp;&nbsp;&nbsp;
      </div>
    </div>
  </div>
  <div class="table-responsive" class="mat-elevation-z8">
    <table mat-table [dataSource]="displayDetails" matSort #tableSort="matSort">
      <ng-container matColumnDef="displayDateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Engagement Date</th>
        <td mat-cell *matCellDef="let element">{{ element.displayDateTime }}</td>
      </ng-container>
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
        <td mat-cell *matCellDef="let element" [style.color]="element.click ? 'blue' : 'black'">
          {{ element.fullName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Telephone</th>
        <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
      </ng-container>
      <ng-container matColumnDef="vehicle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle</th>
        <td mat-cell *matCellDef="let element">{{ element.vehicle }}</td>
      </ng-container>
      <ng-container *ngIf="fordDealer" matColumnDef="esmScore">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>FordDirect Buyer Score</th>
        <td mat-cell *matCellDef="let element" [style.color]="'darkblue'" [style.font-weight]="'bold'">
          {{ element.esmScore }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row (click)="selectRow(row)" *matRowDef="let row; columns: displayedColumns"></tr>
      :
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter {{ lastQuery }}</td>
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[25]" aria-label="Select page of users"></mat-paginator>
  </div>
</div>
