<div class="hl-conversation-list-wrapper">
  <div class="card">
    <div class="p-3 pb-0 mb-1 filter-conversations-wrapper">
      <h5>Filter Conversations</h5>
      <div dropdown class="dropdown no-caret ml-auto mr-3 float-left">
        <button
          dropdownToggle
          class="btn btn-sm btn-outline-primary bg-white mr-0 dropdown-toggle"
          aria-haspopup="true"
          aria-controls="dropdown-basic"
          type="button"
        >
          <span>{{ selectedInterval.description }}</span>
          <i class="fas fa-ellipsis-v"></i>
        </button>
        <div *dropdownMenu class="dropdown-menu dropdown-menu-left dropdown-menu-arrow">
          <a
            class="dropdown-item"
            *ngFor="let interval of intervals"
            href="javascript:void(0)"
            (click)="selectedInterval = interval"
          >
            {{ interval.description }}
          </a>
        </div>
      </div>
      <div class="float-right">
        <div class="custom-control custom-checkbox">
          <input
            class="custom-control-input"
            [(ngModel)]="assignedToMeFilter"
            (change)="filterConversations()"
            id="assignedToMeFilter"
            type="checkbox"
          />
          <label class="custom-control-label" for="assignedToMeFilter">Assigned to Me&nbsp;&nbsp;&nbsp;</label>
        </div>
        <div class="custom-control custom-checkbox">
          <input
            class="custom-control-input"
            [(ngModel)]="touchedFilter"
            (change)="filterConversations()"
            id="touchedFilter"
            type="checkbox"
          />
          <label class="custom-control-label" for="touchedFilter">Untouched</label>
        </div>
        <div *ngIf="isFordDealer" class="custom-control custom-checkbox">
          <input
            class="custom-control-input"
            [(ngModel)]="highScoreFilter"
            (change)="filterConversations()"
            id="highScoreFilter"
            type="checkbox"
          />
          <label class="custom-control-label" for="highScoreFilter">High Propensity</label>
        </div>
        <div *ngIf="isFordDealer" class="custom-control custom-checkbox">
          <input
            class="custom-control-input"
            [(ngModel)]="mediumScoreFilter"
            (change)="filterConversations()"
            id="mediumScoreFilter"
            type="checkbox"
          />
          <label class="custom-control-label" for="mediumScoreFilter">Medium Propensity</label>
        </div>
      </div>
      <div class="input-group">
        <div class="input-group" [ngStyle]="{ width: searchMode ? '95%' : '100%' }">
          <input
            type="text"
            class="form-control form-control-sm"
            [(ngModel)]="searchText"
            (change)="onSearchTextChange()"
            placeholder="Search conversations"
          />
          <div class="input-group-append">
            <button (click)="doSearchConversationsList(searchText)" class="btn btn-primary btn-sm" style="width: 50px">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
        <div class="input-group-append" style="width: 5%; margin: auto">
          <button *ngIf="searchMode" class="close" (click)="clearSearchFilter()"><span>&times;</span></button>
        </div>
      </div>
    </div>
    <div class="p-2">
      <h3>Conversations</h3>
      <small class="px-3" *ngIf="!conversationData.length && !loading">No conversation available.</small>
      <app-loading-spinner *ngIf="!conversationData.length && loading"></app-loading-spinner>
    </div>

    <ul
      [attr.id]="'all-conversations-list'"
      *ngIf="conversationData.length"
      class="list-group"
      appScrollable
      (scrollPosition)="handleScroll($event)"
    >
      <li
        [attr.id]="'conversation-item-' + conversation?.firebaseDocumentId"
        (click)="handleConversationClick(conversation)"
        class="list-group-item"
        *ngFor="let conversation of conversationData"
        [class.selected]="selectedConversationId === conversation?.firebaseDocumentId"
        [class.untouched]="conversation.status !== 'unread' && conversation.touched === false"
        [class.unread]="conversation.status === 'unread' && conversation.touched === false"
        [class.processed]="conversation.touched === true"
      >
        <div class="row">
          <div class="col-9">
            <div class="row">
              <h3>
                &nbsp;
                {{ getUsersName(conversation) }}
              </h3>
            </div>

            <div class="row">
              <p class="conversations-list-text">
                &nbsp;
                <small>{{
                  timestampToDate(conversation.lastMessageDate, conversation.lastUpdated) | date : 'medium'
                }}</small>
              </p>
            </div>
          </div>
          <div class="col-3">
            <a
              *ngFor="let assignment of conversation.assignments"
              class="avatar avatar-sm rounded-circle"
              tooltip="{{ assignment.agentName }}"
              placement="top"
              href="javascript:void(0)"
              [ngStyle]="{ 'background-color': getAvatarColor(assignment) }"
            >
              <div>
                {{ getAgentInitials(assignment.agentName) }}
              </div>
            </a>
          </div>
        </div>
      </li>
      <li class="list-group-item" *ngIf="humanInLoopService.conversationLoading | async">
        <app-loading-spinner></app-loading-spinner>
      </li>
      <li class="list-group-item" *ngIf="humanInLoopService.conversationsDone | async">No more conversations</li>
    </ul>
  </div>
</div>
